import KeyCodes from './key_codes.json'

/**
 * @desc Extract `keyCode` from `KeyboardEvent`.
 */
export const keyCodeFromEvent = (evt: KeyboardEvent | React.KeyboardEvent) => {
  if (evt.which || evt.charCode || evt.keyCode) {
    return evt.which || evt.charCode || evt.keyCode
  }
  if (typeof evt.key !== undefined) {
    const key = evt.key as keyof typeof KeyCodes
    return KeyCodes[key] ?? evt.key.charCodeAt(0)
  }
  return 0
}
