import React, { useEffect, useState, useRef } from 'react'
import { ApiQuery, JSONFetcher, gaEvent } from 'shared'
import { QuerySorting } from 'shared/helpers/api_query/types'
import { Envelope, UseSearchProps, UseSearchReturnValue, UseSearchState } from './types'

const DefaultFilter = {
  state: 'activated',
  min_activity_threshold_reached: true,
  hidden_from_platform: false,
}

const DefaultOrder: QuerySorting = {
  category_boost: 'desc',
  score: 'desc',
  completed: 'asc',
  rank: 'desc',
  last_donation_at: 'desc',
}

export const useSearch = <T,>({
  apiBaseUrl,
  bounds,
  categoryId,
  filter = DefaultFilter,
  query,
}: UseSearchProps): UseSearchReturnValue<T> => {
  const [state, setState] = useState<UseSearchState<T>>({
    loading: true,
    page: 1,
    perPage: 27,
    records: [],
    totalEntries: 0,
    totalPages: 0,
  })
  const abortRef = useRef<AbortController | null>(null)

  const perform = (paginate = false) => {
    // cancel any pending search
    abortRef.current?.abort()
    const abortCtrl = window.AbortController && new AbortController()
    abortRef.current = abortCtrl

    setState((old) => ({ ...old, loading: true }))

    const apiQuery = new ApiQuery(apiBaseUrl)
    apiQuery
      .search(query)
      .addBounds(bounds)
      .page(paginate ? state.page + 1 : 1)
      .per(state.perPage)
      .filter(filter)
      .order(DefaultOrder)
      .category(categoryId)

    JSONFetcher.load<Envelope<T>>({
      url: apiQuery.toUrl(),
      signal: abortCtrl.signal,
      success: (res) =>
        setState((old) => ({
          ...old,
          loading: false,
          page: res.current_page,
          perPage: res.per_page,
          records: paginate ? state.records.concat(res.data) : res.data,
          totalEntries: res.total_entries,
          totalPages: res.total_pages,
        })),
    })
  }

  // call the search API initially and whenever search settings change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(perform, [bounds, categoryId, query, filter, apiBaseUrl])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadMore: React.EventHandler<any> = (event) => {
    event.preventDefault()
    gaEvent('engagement', 'click show_more_projects_link')
    perform(true)
  }
  const hasMore = state.page < state.totalPages

  return { loadMore: hasMore ? loadMore : undefined, ...state }
}
