/* eslint-disable @typescript-eslint/no-explicit-any */
import { ga } from 'shared'

function debounce(callback: (...args: any[]) => void, wait: number, immediate = false) {
  let timeout: NodeJS.Timeout | null = null

  return (...args: any[]) => {
    const callNow = immediate && !timeout
    const next = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback.apply(this, args)
    }

    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) next()
  }
}

const cache = {
  maxScrollPercentage: 24, // If 24% threshold is passed, the first event should occur
  lastLocation: '', // If the location changes, we want to restart scroll tracking
}

export const gaScrollTracking = debounce(
  () => {
    if (window.location.href !== cache.lastLocation) {
      cache.maxScrollPercentage = 24
      cache.lastLocation = window.location.href
    }

    const html = document.documentElement
    const body = document.body
    const pageHeight = Math.max(html.offsetHeight, html.scrollHeight, body.offsetHeight, body.scrollHeight)
    const scrollPos = window.pageYOffset
    const windowHeight = window.innerHeight
    const scrollPercentage = Math.min(100, Math.max(0, Math.round(100 * (scrollPos / (pageHeight - windowHeight)))))

    if (cache.maxScrollPercentage < scrollPercentage) {
      let newValue = scrollPercentage
      if (scrollPercentage >= 25 && scrollPercentage < 50) {
        newValue = 25
      } else if (scrollPercentage >= 50 && scrollPercentage < 75) {
        newValue = 50
      } else if (scrollPercentage >= 75 && scrollPercentage < 90) {
        newValue = 75
      } else if (scrollPercentage >= 90 && scrollPercentage < 100) {
        newValue = 90
      } else if (scrollPercentage >= 100) {
        newValue = 100
      }

      if (newValue > cache.maxScrollPercentage) {
        cache.maxScrollPercentage = newValue
        ga('send', 'event', 'scroll depth', `${cache.maxScrollPercentage}`, window['gaTrackingPath'], {
          nonInteraction: true,
        })
      }
    }
  },
  300,
  true
)
