import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useDebounce } from 'react-use'
import { isEqual } from 'lodash'

export const FormAutoSubmitter = <T,>() => {
  const { values, submitForm, setSubmitting } = useFormikContext<T>()
  const [valuesToSubmit, setValuesToSubmit] = useState<T | null>(null)

  const ref = useRef<T | null>(null)

  useEffect(() => {
    if (ref.current && !isEqual(ref.current, values)) {
      setSubmitting(true)
      setValuesToSubmit(values)
    }
    ref.current = values
  }, [setSubmitting, values])

  useDebounce(() => valuesToSubmit && submitForm(), 500, [valuesToSubmit])

  return null
}
