export class QueryHelper {
  static parseQuery() {
    const query: Record<string, string> = {}
    for (const queryPart of window.location.search.slice(1).split('&')) {
      const [key, value] = queryPart.split('=')
      query[key] = value
    }
    return query
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static toQuery<T extends Record<string, any>>(object: T) {
    const kvPairs = Object.keys(object)
      .map((key) => {
        const value = object[key]
        if (value === undefined || value === null || value === '') return null
        return `${key}=${value}`
      })
      .filter((e) => e)
    if (kvPairs.length === 0) return ''
    return `?${kvPairs.join('&')}`
  }
}
