import React, { useCallback, useState } from 'react'
import { ProjectTeaser } from 'betterplace-react-components'
import { GtmHelper } from 'shared'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore FIXME: https://github.com/joshwnj/react-visibility-sensor/issues/148
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor'
import Cookies from 'universal-cookie'
import { ProjectTeaserWithTrackingProps } from './types'
const cookies = new Cookies()

export const ProjectTeaserWithTracking = ({
  index,
  project,
  teaserTracking,
  trackingPath,
  ...teaserProps
}: ProjectTeaserWithTrackingProps) => {
  const [tracked, setTracked] = useState(false)

  const handleClick = useCallback(() => {
    GtmHelper.pushProjectClicks(project, index + 1, trackingPath)
    if (teaserTracking) {
      const expires = new Date()
      expires.setTime(expires.getTime() + 3600 * 1000 * 3)
      cookies.set(`teaser-tracker-p${project.id}`, teaserTracking, { path: '/', expires })
    }
  }, [index, project, teaserTracking, trackingPath])

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (!isVisible || tracked) return
      GtmHelper.pushProjectImpressions(project, index + 1, trackingPath)
      setTracked(true)
    },
    [index, project, tracked, trackingPath]
  )

  return (
    <VisibilitySensor scrollCheck onChange={handleVisibilityChange}>
      <ProjectTeaser project={project} textColor="black" onClick={handleClick} {...teaserProps} />
    </VisibilitySensor>
  )
}
