import React, { useState, useCallback, useMemo } from 'react'
import { Modal } from './types'

/**
 * @desc hook function providing modal functionality
 */
export const useModal = (): Modal => {
  const [active, setActive] = useState(false)
  const show: (event?: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      if (event) event.preventDefault()
      setActive(true)
    },
    [setActive]
  )
  const hide: (event?: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      if (event) event.preventDefault()
      setActive(false)
    },
    [setActive]
  )
  const background = useMemo(
    () =>
      active ? (
        <div
          className="modal"
          style={{ display: 'block', background: 'black', opacity: 0.1 }}
          onClick={() => setActive(false)}
        ></div>
      ) : null,
    [setActive, active]
  )

  return { active, background, hide, show }
}
