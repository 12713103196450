import React from 'react'
import { LogoSpinner } from 'shared'
import classNames from 'classnames'
import { ButtonProps } from './types'

const loadingStyle = {
  color: 'transparent',
  textShadow: 'none',
  lineHeight: 0,
  transition: 'none',
}

export const Button = ({ loading, className, children, smallSpinner, ...buttonProps }: ButtonProps): JSX.Element => {
  return (
    <button
      className={classNames('btn', className)}
      disabled={loading} // Can be overriden with buttonProps
      style={loading ? loadingStyle : {}}
      type="submit"
      {...buttonProps}
    >
      {loading && <LogoSpinner smallSpinner />}
      {children}
    </button>
  )
}
