import accounting from 'accounting'

const accounting_configuration: Record<string, accounting.Settings> = {
  de: {
    currency: {
      symbol: '\u20ac',
      format: '%v %s',
      decimal: ',',
      thousand: '.',
      precision: 2,
    },
    number: {
      precision: 2,
      decimal: ',',
      thousand: '.',
    },
  },
  en: {
    currency: {
      symbol: '\u20ac',
      format: '%s%v',
      decimal: '.',
      thousand: ',',
      precision: 2,
    },
    number: {
      precision: 2,
      decimal: '.',
      thousand: ',',
    },
  },
}
const locale =
  (document.querySelector('html')?.getAttribute('lang') as keyof typeof accounting_configuration | null | undefined) ||
  'de'
const locale_config = accounting_configuration[locale]
if (locale_config) {
  accounting.settings = locale_config
}

export const formatMoney = accounting.formatMoney

export function formatCents(
  cents: number,
  opts: { precision?: number; format?: string; symbol?: string } = {}
): string {
  const precision = opts.precision ?? (cents % 100 ? 2 : 0)
  return formatMoney(cents / 100, { ...opts, precision })
}

/**
 * @desc Treat string amounts kind of language agnostic. Supports '.' or ',' as a delimiter for fractional digits. Use it in combination with `sanitizeMoney`.
 */
export const parseCents = (sanitizedAmount: string): number => {
  if (sanitizedAmount === '') return 0
  const floatText = sanitizedAmount.replace(',', '.')
  const amountInCurrencyUnits = parseFloat(floatText) || 0
  return Math.round(amountInCurrencyUnits * 100)
}

/**
 * @desc Force money containing string into a sensible shape: only one , or . is allowed as a delimiter. No separator, no chars, no -, no bs
 */
export const sanitizeMoney = (value: string): string => {
  return value
    .replace(/[^\d.,]/g, '') // drop anything except digits, dots and commas
    .replace(/^([.,]{1,1})/, '0$1') // insert a zero before any leading coma or dot
    .replace(/^0{2,}/, '0') // limit to one leading 0
    .replace(/^0([\d]+)([\d,.]*)/, '$1$2') // drop leading zero if another digit is already provided
    .replace(/[.,]([^.,]*)(?=[.,])/g, '$1') // drop any dots and commas except the last one
    .replace(/([.,]\d\d).*$/, '$1') // limit to two decimal places
}
