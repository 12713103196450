// Mimic bootstrap collapse
// https://medium.com/dailyjs/mimicking-bootstraps-collapse-with-vanilla-javascript-b3bb389040e7
// map our commands to the classList methods

const fnmap: { [key: string]: Extract<keyof DOMTokenList, 'toggle' | 'add' | 'remove'> } = {
  toggle: 'toggle',
  show: 'add',
  hide: 'remove',
}

const collapse = (selector: string, cmd: keyof typeof fnmap) => {
  const targets: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
  targets.forEach((target) => {
    target.classList[fnmap[cmd]]('show')
  })
}

export const initializeCollapse = () => {
  const triggers = document.querySelectorAll('[data-toggle="collapse"]')

  triggers.forEach((element) => {
    element.addEventListener('click', (event) => {
      const target_selector = element.getAttribute('data-target')
      if (!target_selector) return
      collapse(target_selector, 'toggle')
      element.classList.toggle('collapse-visible')
    })
  })
}
