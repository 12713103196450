import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel, A11y, Keyboard, Lazy } from 'swiper'
import { ApiQuery, findApiV4Link, useJsonApi, useMobile } from 'shared'
import { GalleryProps, PictureArray, PictureProps, PicturesApiResult } from './types'
import { NavigationArrow } from 'shared'
import classNames from 'classnames'
import styles from './styles.module.css'

SwiperCore.use([Navigation, Mousewheel, A11y, Keyboard, Lazy])

export const Gallery = ({
  profilePictureUrlMobile1x,
  profilePictureUrlMobile2x,
  profilePictureUrlDesktop1x,
  profilePictureUrlDesktop2x,
  apiUrl,
}: GalleryProps): JSX.Element | null => {
  const [pictures, setPictures] = useState<PictureArray>([])
  const query = new ApiQuery(apiUrl)
  //We display maximum 200 (this is the max amount per page)
  const currentPageUrl = query.per(200).toUrl()
  const { data, loading } = useJsonApi<PicturesApiResult>(currentPageUrl)
  const [hasMultipleItems, setHasMultipleItems] = useState(false)

  // Set picture data (URLs)
  useEffect(() => {
    loading || (data?.data && setPictures(data.data))
  }, [loading, data?.data])

  useEffect(() => {
    if (data?.total_entries) {
      setHasMultipleItems(true)
    }
  }, [data?.total_entries])

  const additionalImages = pictures?.map((picture, index) => (
    <SwiperSlide key={`pic-${picture.id}`} className={styles.swiperSlide}>
      <Picture {...picture} index={index} total={data?.total_entries || undefined} close={close} />
      <div className="swiper-lazy-preloader"></div>
    </SwiperSlide>
  ))

  if (!hasMultipleItems || loading)
    return (
      <div data-testid="gallery">
        <div className="image-container covering-images">
          {/* As of 2022, the actual Desktop1x-url will point to a 618px wide image, but we live with 2px underdefinition*/}
          <img
            src={profilePictureUrlDesktop1x}
            srcSet={`${profilePictureUrlMobile1x} 450w, ${profilePictureUrlDesktop1x} 620w, ${profilePictureUrlMobile2x} 900w, ${profilePictureUrlDesktop2x} 1240w`}
            sizes="(max-width: 450px) 100vw, 620px"
            className="single-image covering-image"
            alt=""
          />
        </div>
      </div>
    )
  else
    return (
      <div data-testid="gallery">
        <Swiper
          className={styles.swiperContainer}
          slidesPerView={1}
          navigation={{
            nextEl: '#gallery-button-next',
            prevEl: '#gallery-button-prev',
            disabledClass: `${styles.isDisabled}`,
          }}
          keyboard
          centeredSlides
          roundLengths
          mousewheel={{ forceToAxis: true }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          a11y={{
            enabled: true,
          }}
          preloadImages={false}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 3,
          }}
        >
          {profilePictureUrlDesktop1x && (
            <SwiperSlide className={styles.swiperSlide}>
              {/* As of 2022, the actual Desktop1x-url will point to a 618px wide image, but we live with 2px underdefinition*/}
              <img
                srcSet={`${profilePictureUrlMobile1x} 450w, ${profilePictureUrlDesktop1x} 620w, ${profilePictureUrlMobile2x} 900w, ${profilePictureUrlDesktop2x} 1240w`}
                sizes="(max-width: 450px) 95vw, 620px"
                src={profilePictureUrlDesktop1x}
                className={styles.swiperPicture}
                alt=""
              />
            </SwiperSlide>
          )}
          {additionalImages}
          <div className={styles.galleryControl}>
            <button className={classNames(styles.galleryButton, styles.galleryButtonPrev)} id="gallery-button-prev">
              <NavigationArrow direction="left" additionalClassName="ml-0" size="small" />
            </button>
            <button className={classNames(styles.galleryButton, styles.galleryButtonNext)} id="gallery-button-next">
              <NavigationArrow direction="right" additionalClassName="ml-0" size="small" />
            </button>
          </div>
        </Swiper>
      </div>
    )
}

const Picture = ({ close, index, total, ...picture }: PictureProps): JSX.Element => {
  const srcUrl1x = useMobile()
    ? findApiV4Link({ ofObject: picture, withRel: 'limit_450x235' })
    : findApiV4Link({ ofObject: picture, withRel: 'limit_620x323' })
  const srcUrl2x = useMobile()
    ? findApiV4Link({ ofObject: picture, withRel: 'limit_450x235_2x' })
    : findApiV4Link({ ofObject: picture, withRel: 'limit_620x323_2x' })
  const [showPictureInfo, setShowPictureInfo] = useState(false)

  return (
    <figure>
      <img
        // We need data-srcset instead of srcSet for the lazy loading of the swiper
        data-srcset={`${srcUrl1x}, ${srcUrl2x} 2x`}
        className={classNames('swiper-lazy', styles.swiperPicture)}
        alt=""
      />
      {picture.description && (
        <>
          <button className={styles.btnOverImage} onClick={() => setShowPictureInfo(true)}>
            <i className="fa fa-info-circle pr-1" style={{ color: 'white' }}></i>
            <span>{I18n.t('ppp.gallery.info_trigger')}</span>
          </button>
          <figcaption className={classNames(styles.pictureInfo, showPictureInfo ? 'flex' : 'hidden')}>
            <span>{picture.description}</span>
            <button onClick={() => setShowPictureInfo(false)}>
              <i className="fas fa-times" style={{ color: 'white' }}></i>
            </button>
          </figcaption>
        </>
      )}
    </figure>
  )
}
