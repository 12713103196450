/**
 * @desc Wraps a string with quotes depending on the locale
 */
export const quote = (str: string) => (I18n.locale === 'de' ? `„${str}“` : `“${str}“`)

/**
 * @desc Truncates the string to the specified length and returns the truncated string postfixed with ellipsis character
 */
export const truncate = (text: string, { length }: { length: number }): string => {
  return text.length > length ? `${text.substring(0, length)}…` : text
}

/**
 * @func toSentence
 * @desc Joins individual words with locale dependent conjunction
 * @param  {...(string | number)} words
 * @returns {string}
 */
export const toSentence = (...words: (string | number)[]): string => {
  const and = I18n.locale === 'de' ? 'und' : 'and'

  switch (words.length) {
    case 0:
      return ``
    case 1:
      return `${words[0]}`
    case 2:
      return `${words[0]} ${and} ${words[1]}`
    default:
      return `${words.slice(0, -1).join(', ')} ${and} ${[...words].pop()}`
  }
}

export const formatNumber = (number: number, { precision }: { precision?: number } = {}): string => {
  const options = {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }

  const formatter = Intl.NumberFormat(I18n.locale, options)

  if ('format' in formatter) {
    return formatter.format(number)
  }

  // fallback
  return number.toString()
}

/**
 * @description Exactly matches rails' String#underscore (except for custom acronyms)
 */
export const underscore = (str: string): string => {
  if (!/[A-Z-]|::/.test(str)) return str

  return (
    str
      .replace(/::/g, '/')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .replace(/([A-Z\d]+)([A-Z][a-z])/g, (_m: any, g1: any, g2: any) => `${g1}_${g2}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .replace(/([a-z\d])([A-Z])/g, (_m: any, g1: any, g2: any) => `${g1}_${g2}`)
      .replace(/-/g, '_')
      .toLowerCase()
  )
}
