import React, { useState, useCallback } from 'react'
import { DSIHtml, Modal } from 'shared'
import classNames from 'classnames'

export const AbTaxNotice = ({ as, className }: { as?: string; className?: string }): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => setShowModal(false), [setShowModal])
  const openModal = useCallback(() => setShowModal(true), [setShowModal])

  return (
    <>
      <DSIHtml
        as={as}
        value={
          I18n.locale === 'de'
            ? "<strong>Du erhältst eine Spendenbescheinigung</strong> vom <u style='cursor: pointer;'>Spendenempfänger</u> betterplace (gut.org gAG)."
            : "<strong>You will receive a donation receipt</strong> from betterplace (gut.org gAG), the initial <u style='cursor: pointer;'>recipient of the donation</u>."
        }
        onClick={openModal}
        className={classNames(className)}
      />
      <Modal
        id="thank-you-modal"
        onHide={hideModal}
        show={showModal}
        header={I18n.t('shared.tax_deductibility_modal_headline')}
      >
        <p>
          <DSIHtml value={I18n.t('shared.tax_deductibility_modal_html')} />
        </p>
        <div className="text-right">
          <a className="btn btn-primary" onClick={hideModal}>
            {I18n.t('misc.close')}
          </a>
        </div>
      </Modal>
    </>
  )
}
