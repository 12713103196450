import { useJsonApi } from '../use_json_api'
import { ProjectApiResult, ProjectDefaults, ProjectResultApiResponse, ProjectStatus, ProjectWithStatus } from './types'

/**
 * @desc Load a project from a given api_v4 URL with sensible preview data and status mixed in
 */
export const useProject = (apiUrl: string): ProjectWithStatus => {
  const { data, loading } = useJsonApi<ProjectResultApiResponse>(apiUrl)
  const merged = data ? { ...defaults, ...data } : defaults
  return { ...merged, loading, status: projectStatus(data, loading) }
}

const defaults: ProjectDefaults = {
  id: undefined,
  donations_count: 0,
  comments_count: 0,
  blog_post_count: 0,
  progress_percentage: 0,
  open_amount_in_cents: 0,
  donated_amount_in_cents: 0,
}

/**
 * @desc Display state calculator, use with project result from api_v4
 */
export const projectStatus = (
  project: ProjectApiResult['data'],
  loading: boolean | undefined = false
): ProjectStatus => {
  if (loading) return 'activated'
  // optimistically show activated state UI
  if (project?.completed_at) return 'completed'
  if (project?.closed_at) return 'closed'
  if (project?.activated_at) return project.donations_prohibited ? 'blocked' : 'activated'
  return 'preparing'
}
