import { createElement } from 'react'
import { render } from 'react-dom'

export const mountOnId = <P extends {}>(component: React.ComponentType<P>, domId: string, data?: P) => {
  const domNode = document.getElementById(domId)
  if (domNode) {
    //Todo: Cast non string properties to their respective types (e.g. numbers are currently not real numbers but strings)
    const newElement = createElement(component, data ?? (domNode.dataset as P))
    render(newElement, domNode)
  }
}

export const mountOnDataBehavior = <P extends {}>(component: React.ComponentType<P>, behavior: string, data?: P) => {
  const nodes: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-behavior=${behavior}]`)
  Array.from(nodes).forEach((node) => {
    // We remove the attribute to guard that we select it multiple times.
    // But we want to keep the info about the component in an special atribute.
    node.setAttribute('data-info-initialized-behavior', node.getAttribute('data-behavior') || '')
    node.removeAttribute('data-behavior')
    const { behavior: _, ...dataAttributes } = node.dataset
    const tag = createElement(component, data ?? (dataAttributes as P))
    render(tag, node)
  })
}
