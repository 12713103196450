import React from 'react'
import placeholder from './placeholder.svg'
import { NewsTeaserProps } from './types'
import styles from './styles.module.css'
import classNames from 'classnames'
import { truncate } from 'shared/helpers/text_helpers'
import { DSIHtml, gaEvent } from 'shared'

export const NewsTeaser = ({ news }: NewsTeaserProps): JSX.Element => {
  const imageUrlPattern = / src="(https:\/\/betterplace-assets.betterplace.org\/uploads[^"?]+\.(jpg|jpeg|gif|png))"/
  const match = news.body.match(imageUrlPattern)

  //We have to make sure the different data structures work
  //There is a ticket to clean this up: https://betterplace.atlassian.net/browse/DEV-209
  const href = 'links' in news ? news.links[1].href : news.href
  const ownerTitle = 'links' in news ? null : news.owner_title
  const ownerType = 'links' in news ? null : news.owner_type
  const image = 'links' in news ? null : news.image
  const imageUrl = image || (match && match[1])

  return (
    <a href={href} className={styles.container} onClick={() => gaEvent('news', 'click teaser')}>
      {imageUrl ? (
        <div
          className={styles.imageContainer}
          style={{
            backgroundImage: 'url(' + imageUrl + ')',
          }}
        ></div>
      ) : (
        <div className={classNames(styles.imageContainer, styles.placeholderImageContainer)}>
          <img src={placeholder} alt="" className={styles.placeholderImage} />
        </div>
      )}

      <div className={styles.contentContainer}>
        <div className={styles.date}>{I18n.l('date.formats.default2', news.published_at)}</div>
        {/* Everything inside has to be inline in order to work in Safari. */}
        <div className={styles.truncateBox}>
          <h3 className={classNames(styles.title, ' mt-0')}>
            {news.title}
            <br />
          </h3>
          {/* Hack to add some white space between inline elements. */}
          <span style={{ fontSize: '2rem' }} />
          <span className={styles.preview}>
            {truncate(
              news.body
                .replace(/<(.|\n)*?>/g, '') // Remove HTML tags
                .replace(/\s\s+/g, ' '), // Remove multiple white spaces
              { length: 150 }
            )}
          </span>
        </div>
      </div>
      {ownerTitle && (
        <div className={styles.ownerInfoContainer}>
          <div className={styles.ownerInfoBox}>
            <DSIHtml
              as="span"
              value={
                ownerType === 'Project'
                  ? I18n.t('shared.news_teaser.project_info_html', { title: ownerTitle })
                  : I18n.t('shared.news_teaser.fundraising_event_info_html', { title: ownerTitle })
              }
            />
          </div>
        </div>
      )}
    </a>
  )
}
