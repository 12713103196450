import React, { useEffect, useState } from 'react'
import zxcvbn from 'zxcvbn'

export const PasswordStrengthMeter = ({ inputId }: { inputId: string }): JSX.Element | null => {
  const [password, setPassword] = useState('')
  useEffect(() => {
    const input = document.getElementById(inputId) as HTMLInputElement
    const listener = (e: Event) => setPassword((e.target as HTMLInputElement).value)
    input.addEventListener('input', listener)
    return () => input.removeEventListener('input', listener)
  }, [inputId])
  if (!password) return null

  // TODO: Maybe use debounce
  const strength = password.length < 8 ? 0 : zxcvbn(password).score

  return (
    <>
      <progress className={`password-strength-meter-progress strength-${strength + 1}`} value={strength + 1} max="5" />
      <p className="password-strength-meter-label">
        {strengthDescriptions[I18n.locale === 'de' ? 'de' : 'en'][strength]}
      </p>
    </>
  )
}

const strengthDescriptions = {
  de: ['Passwort zu kurz', 'Schwaches Passwort', 'Mäßiges Passwort', 'Gutes Passwort', 'Starkes Passwort'],
  en: ['Password too short', 'Weak password', 'Fair password', 'Good password', 'Strong password'],
}
