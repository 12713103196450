import { AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { JSONFetcher } from '../../helpers/json_fetcher'
import { UseJsonApiState } from './types'

/**
 * @desc Hook to use JSON APIs, e.g. api_v4. Changing the passed URL automatically triggers new requests and data updates.
 */
export const useJsonApi = <T,>(url: string, config?: AxiosRequestConfig): UseJsonApiState<T> => {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const abortCtrl = new AbortController()
    setLoading(true)
    url &&
      JSONFetcher.load({
        url,
        config: { ...(config ?? {}), signal: config?.signal ?? abortCtrl.signal },
        success: (data: T) => {
          setData(data)
          setLoading(false)
        },
      })
    return () => abortCtrl.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return { data, loading }
}
