import React, { useCallback } from 'react'
import { formatCents, parseCents } from 'shared'

/**
 * @TODO refactor into a component using forwarded ref instead (!!no props mutations!!)
 */
export const CentsInput = (props: { control: HTMLInputElement; initialValue: string }) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      props.control.value = `${parseCents(event.target.value)}`
    },
    [props.control]
  )

  return (
    <div className="input-group">
      <input
        style={{ zIndex: 1 }}
        type="text"
        onChange={handleChange}
        defaultValue={formatCents(+props.initialValue, { format: '%v' })}
      />
      <div className="input-group-append">
        <span className="input-group-text">€</span>
      </div>
    </div>
  )
}
