import React, { useCallback } from 'react'
import { FormGroup, ColorInput } from 'shared'
import { connect } from 'formik'
import { FormColorInputProps } from './types'
import { ColorChangeHandler } from 'react-color'

export const FormColorInput: React.ComponentType<FormColorInputProps> = connect(
  ({ field, form, formik: { setFieldValue }, transparentOption = false, ...props }) => {
    const onColorChange: ColorChangeHandler = useCallback(
      ({ hex }: { hex: string }) => setFieldValue(field.name, hex),
      [setFieldValue, field.name]
    )
    return (
      <FormGroup field={field} form={form} {...props}>
        <ColorInput
          onColorChange={onColorChange}
          transparentOption={transparentOption}
          value={field.value || 'transparent'}
        />
      </FormGroup>
    )
  }
)
