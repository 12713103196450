import React, { useState, useCallback } from 'react'
import { DSIHtml, Modal } from 'shared'
import classNames from 'classnames'

/**
 * @param as HTML element (defaults to span from DSIHtml)
 * @param className additional css class names for the element
 * @param text omit for sensible default
 */
export const TaxNotice = ({ as, className, text }: { as?: string; className?: string; text?: string }): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => setShowModal(false), [setShowModal])
  const openModal = useCallback(() => setShowModal(true), [setShowModal])
  const valueText = text ? text : I18n.t('shared.tax_deductibility_notice_html')

  return (
    <>
      <DSIHtml as={as} value={valueText} onClick={openModal} className={classNames(className)} />
      <Modal
        id="thank-you-modal"
        onHide={hideModal}
        show={showModal}
        header={I18n.t('shared.tax_deductibility_modal_headline')}
      >
        <p>
          <DSIHtml value={I18n.t('shared.tax_deductibility_modal_html')} />
        </p>
        <div className="text-right">
          <a className="btn btn-primary" onClick={hideModal}>
            {I18n.t('misc.close')}
          </a>
        </div>
      </Modal>
    </>
  )
}
