import React from 'react'
import { OverlayTrigger, Popover as RBPopover } from 'react-bootstrap'
import { PopoverIconProps, PopoverProps } from './types'

/**
 * TODO multiple issues with props, sometimes popoverId or container are passed, but these props are noop, should they be included or should we rather remove them altogether
 */
export const Popover = ({ placement, popover, trigger, children, popoverId = '' }: PopoverProps): JSX.Element => {
  const popoverContent = popover ? <div dangerouslySetInnerHTML={{ __html: popover }} /> : children

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      flip
      popperConfig={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom'],
            },
          },
        ],
      }}
      overlay={
        <RBPopover id={popoverId}>
          <RBPopover.Content>{popoverContent}</RBPopover.Content>
        </RBPopover>
      }
      // rootClose - don’t use since it does not work on native iOS devices
    >
      {trigger}
    </OverlayTrigger>
  )
}

/**
 * TODO trigger was overwritten by the props trigger, establish whether it is the desired behaviour
 */
export const PopoverIcon = ({ icon = '', iconClass, ...props }: PopoverIconProps) => (
  <Popover
    trigger={
      <button
        type="button"
        className={iconClass || `generic-popover-icon ${icon}`}
        aria-label="Tooltip"
        data-ci-type="tooltip-trigger"
      />
    }
    {...props}
  />
)

export const PopoverText = ({ trigger, ...props }: PopoverProps) => (
  <Popover trigger={<span>{trigger}</span>} {...props} />
)
