import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { gaEvent, Modal, ShareButtons } from 'shared'
import { ModalContentProps, SharingGridButtonProps } from './types'

export const SharingGridButton = ({
  shareUrl,
  gaLabel,
  platforms,
  modalHeader,
  buttonLabel,
  className,
}: SharingGridButtonProps) => {
  const [show, setShow] = useState(false)

  const handleClick = useCallback(() => {
    gaEvent('engagement', 'click sharing_modal_open')
    setShow(true)
    const ele = document.activeElement as HTMLElement | null
    ele?.blur()
  }, [])

  const onHide = useCallback(() => {
    gaEvent('engagement', 'click sharing_modal_close')
    setShow(false)
  }, [])

  return (
    <>
      <button onClick={handleClick} className={classNames('btn', className)}>
        {buttonLabel}
      </button>

      <Modal
        show={show}
        onHide={onHide}
        content={<ModalContent shareUrl={shareUrl} gaLabel={gaLabel} platforms={platforms} />}
        header={modalHeader}
      />
    </>
  )
}

const ModalContent = ({ shareUrl, gaLabel, platforms }: ModalContentProps): JSX.Element => (
  <>
    <p>{I18n.t('projects.show.stats.share_modal_text')}</p>
    <div className="projects-sharing-grid">
      <ShareButtons
        project
        platforms={platforms}
        mobileOnly={['whatsapp', 'messenger']}
        shape="roundWithLabel"
        shareUrl={shareUrl}
        withLabel
        gaCategory="engagement" // TODO: gaCategory is unused in ShareButtons
        gaLabel={gaLabel}
      />
    </div>
  </>
)
