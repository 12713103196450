import React, { useState } from 'react'
import { Modal, gaEvent } from 'shared'
import { Button } from '../button'
import { ModalTriggerProps } from './types'

export const ModalWithTrigger = ({
  clicktrackingAction,
  cssClass,
  trigger,
  title,
  children,
  buttonTriggered,
  content = '',
  size,
  closeButtonText,
}: ModalTriggerProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => setShowModal(false)
  const openModal = () => {
    setShowModal(true)
    if (clicktrackingAction) {
      gaEvent('engagement', clicktrackingAction)
    }
  }

  return (
    <>
      {buttonTriggered ? (
        <button className={cssClass || 'btn btn-large btn-danger'} onClick={openModal}>
          {trigger}
        </button>
      ) : (
        <span className={cssClass || 'simulated-link'} onClick={openModal}>
          {trigger}
        </span>
      )}

      <Modal
        onHide={hideModal}
        show={showModal}
        header={title}
        size={size}
        footer={
          closeButtonText ? (
            <Button onClick={hideModal} className="btn-large btn-primary">
              {closeButtonText}
            </Button>
          ) : null
        }
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {children}
      </Modal>
    </>
  )
}
