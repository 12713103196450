import { AxiosError } from 'axios'
import { ReportableError } from './types'

export const report = (error: ReportableError, context?: Record<string, unknown>) => {
  const Honeybadger = window?.Honeybadger

  if (!Honeybadger) {
    console.error(error)
    return
  }

  if (context) {
    Honeybadger.setContext(context)
  }

  if (error instanceof AxiosError) {
    if (error.response) {
      Honeybadger.setContext({ status: error.response.status, data: error.response.data })
    } else if (error.request) {
      Honeybadger.setContext({ status: error.request.status, data: error.request.data })
    }
  }

  Honeybadger.setContext({ tags: 'frontend' })
  Honeybadger.notify(error)
  Honeybadger.clear()
}
