import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import classnames from 'classnames'
import { FormRadioProps } from './types'

export const FormRadio = ({
  field,
  value = '',
  disabled,
  label = '',
  className,
  disabledInfo,
}: FormRadioProps): JSX.Element => {
  const content = (
    <label
      htmlFor={value}
      className={classnames(className, 'flex', 'generic-custom-radio-field', {
        'is-disabled': disabled,
      })}
    >
      <input
        {...field}
        id={value}
        type="radio"
        value={value}
        checked={value === field.value}
        className={value === field.value ? 'active' : ''}
        disabled={disabled}
      />
      <span className="generic-custom-radio" />
      <span>{label}</span>
    </label>
  )

  if (disabled && disabledInfo) {
    return (
      <OverlayTrigger
        trigger={['hover', 'click', 'focus']}
        placement="bottom"
        overlay={
          <Popover id={`popover-bottom`}>
            <div className="popover-body">{disabledInfo}</div>
          </Popover>
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        container={document.getElementsByClassName('generic-popover-constraints')[0] as any}
      >
        {content}
      </OverlayTrigger>
    )
  }
  return content
}
