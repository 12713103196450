// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@tai... Remove this comment to see the full error message
const plugin = require('@tailwindcss/postcss7-compat/plugin')
// Revert to the following when postcss 8 is ready,
//   see https://www.pivotaltracker.com/story/show/176681558
// const plugin = require('tailwindcss/plugin')

// ==================== REMINDER ====================
// Whenever we update this file,
//   we should also run `$ script/tailwind_unpurged``
//   to refresh our static tailwind_unpurged.css

module.exports = {
  purge: false,
  theme: {
    colors: {
      // ======== ==================================================================== ========
      // ======== Keep in sync with app/javascript/stylesheets/initialize/_colors.scss ========
      // ======== Keep in sync with Jekyll =========================================== ========
      // ======== ==================================================================== ========
      // ======== Color definition & legay colors in app/helpers/styleguide_helper.rb  ========
      // ======== ==================================================================== ========

      // Note: We dont use the default tailwind nested hash to make the colors searchable.
      'green-100': '#F5FCCC',
      'green-200': '#EAF99A',
      'green-300': '#D6EF66',
      'green-400': '#BEDF40',
      'green-500': '#9ECB0A',
      'green-600': '#85AC1C',
      'green-700': '#549205',
      'green-800': '#357C00',
      'green-900': '#2A5807',

      'teal-100': '#E4FBEE',
      'teal-200': '#C9F7E2',
      'teal-300': '#A7E7D0',
      'teal-400': '#88D0BD',
      'teal-500': '#60B2A4',
      'teal-600': '#309B89',
      'teal-700': '#2B8475',
      'teal-800': '#1E6761',
      'teal-900': '#114E47',

      'yellow-100': '#fff4d2',
      'yellow-200': '#fbe8af',
      'yellow-300': '#f9da7c',
      'yellow-400': '#F6CE46',
      'yellow-500': '#f3b700',
      'yellow-600': '#f3a900',
      'yellow-700': '#f39600',
      'yellow-800': '#D86C00',
      'yellow-900': '#C44400',

      'red-100': '#ffe4e1',
      'red-200': '#ffc4c4',
      'red-300': '#ffa6a6',
      'red-400': '#ff9490',
      'red-500': '#FF6F6C',
      'red-600': '#f24e5a',
      'red-700': '#d32b43',
      'red-800': '#b11b3e',
      'red-900': '#931C38',

      'purple-100': '#F7DAE8',
      'purple-200': '#F0B7D8',
      'purple-300': '#D387BA',
      'purple-400': '#A75C96',
      'purple-500': '#833C79',
      'purple-600': '#6D2C64',
      'purple-700': '#612058',
      'purple-800': '#4D1A43',
      'purple-900': '#381835',

      'gray-100': '#f8f8f8',
      'gray-200': '#EEEEEE',
      'gray-300': '#CCCCCC',
      'gray-400': '#ACACAC',
      'gray-500': '#919191',
      'gray-600': '#7b7b7b',
      'gray-700': '#636363',
      'gray-800': '#3F3F3F',
      'gray-900': '#282828',

      'orange-100': '#ffe3c2',
      'orange-200': '#FFC890',
      'orange-300': '#FFB67C',
      'orange-400': '#FF9C62',
      'orange-500': '#FF854D',
      'orange-600': '#DB6038',
      'orange-700': '#AC451F',
      'orange-800': '#6F1F06',
      'orange-900': '#291501',

      'blue-100': '#E0F4F9',
      'blue-200': '#ABE7F8',
      'blue-300': '#7AD0EC',
      'blue-400': '#4CC0E0',
      'blue-500': '#00A0C8',
      'blue-600': '#0183AB',
      'blue-700': '#005D90',
      'blue-800': '#004274',
      'blue-900': '#003060',

      'legacy-blue-admin': '#28638c',
      'legacy-gray-blue-200': '#AECFD1',

      'white': '#fff',
      'black': '#000',
      'twitter': '#1DA1F2',
      'facebook': '#4E69A2',
      'wirwunder': '#ff0000',
    },
    // See postcss.config.js
    // We cannot use the dot-version 1.5 since they break the matcher for slim-files
    // Instead we generate our own spacing-set with dash-versions:
    spacing: {
      'px': '1px',
      '0': '0px',
      '005': '0.125rem', // Custom, was 0.5
      '1': '0.25rem',
      '105': '0.375rem', // Custom, was 1.5
      '2': '0.5rem',
      '205': '0.625rem', // Custom, was 2.5
      '3': '0.75rem',
      '305': '0.875rem', // Custom, was 3.5
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '18': '4.5rem', // Custom
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
    },
    // We only allow non-prefixed and desktop:* util classes for now
    //   https://www.pivotaltracker.com/story/show/176817371
    screens: {
      desktop: '451px',
    },
    // We dont use .font-sans and .font-serif since all is Fira Sans. We use our historically custom font-mono list.
    fontFamily: {
      mono: 'Monaco, Menlo, Consolas, ui-monospace, "Courier New", monospace',
    },
    extend: {
      textColor: {
        muted: '#636363', // retry using theme('colors['gray-700']')
      },
      margin: {
        '0!': '0px !important',
      },
      padding: {
        '0!': '0px !important',
      },
      maxWidth: {
        container: '940px', // Bootstrap Grid Container 960px - 2x 10px padding
      },
      minHeight: {
        170: '170px', // .generic-content-header
      },
      height: {
        '005': '2px',
      },
      width: {
        '005': '2px',
      }
    },
  },
  variants: {
    extend: {
      rotate: ['group-hover'],
      backgroundColor: ['focus'],
      textColor: ['active'],
    },
  },
  corePlugins: {
    container: false,
    // We don't want to use ring* ATM, https://www.pivotaltracker.com/story/show/177162847
    ringWidth: false,
    ringColor: false,
    ringOpacity: false,
    ringOffsetWidth: false,
    ringOffsetColor: false,
  },
}
