import React from 'react'

export const OrganisationInfo = ({
  carrierProfilePictureUrl,
  carrierName,
  city,
  country,
  pictureWidth = 50,
}: OrganisationInfoProps) => {
  return (
    <div className="flex desktop:mb-0">
      <div className="flex-shrink-0">
        <div className="flex justify-center items-center border border-solid border-gray-200 mr-2">
          {/* Todo: Clean up types */}
          <img src={carrierProfilePictureUrl || ''} alt={carrierName || ''} style={{ width: `${pictureWidth}px` }} />
        </div>
      </div>
      <div>
        <span
          className="font-bold block"
          dangerouslySetInnerHTML={{
            __html: I18n.t('projects.show.organisation_info.subline_carrier_html', {
              carrier_name: carrierName || '?',
            }),
          }}
        />{' '}
        <span
          className="text-gray-500 block"
          dangerouslySetInnerHTML={{
            __html:
              city || country
                ? I18n.t('projects.show.organisation_info.subline_location', {
                    location: [city, country].filter(Boolean).join(', '),
                  })
                : I18n.t('projects.show.organisation_info.fallback_location'),
          }}
        />
      </div>
    </div>
  )
}
