import React, { useState, useEffect, useCallback } from 'react'
import { ColorChangeHandler, ColorResult, TwitterPicker } from 'react-color'
import { keyCodeFromEvent, themeColor } from 'shared'
import { ColorInputProps } from './types'
const popoverStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  top: 40,
  left: -5,
}
const coverStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export const ColorInput = ({ onColorChange, transparentOption = false, value }: ColorInputProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const handleEnter: React.KeyboardEventHandler = useCallback((event) => {
    const code = keyCodeFromEvent(event)
    // Enter
    if (code === 13) {
      event.preventDefault()
      setOpen(false)
    }
  }, [])

  const handleChangeComplete: ColorChangeHandler = useCallback(
    (change, e) => onColorChange({ hex: change.hex.replace('#', '') } as ColorResult, e),
    [onColorChange]
  )

  const openPicker = useCallback(() => setOpen(true), [])

  const closePicker = useCallback(() => setOpen(false), [])

  const colors = [
    themeColor('green-500'),
    themeColor('green-700'),
    themeColor('green-900'),
    themeColor('yellow-400'),
    themeColor('orange-500'),
    themeColor('red-700'),
    themeColor('red-900'),
    themeColor('orange-700'),
    themeColor('blue-400'),
    themeColor('blue-700'),
    themeColor('blue-900'),
    themeColor('purple-400'),
    themeColor('purple-700'),
    themeColor('gray-500'),
    themeColor('gray-700'),
    themeColor('gray-900'),
  ]

  if (transparentOption) {
    colors.push('transparent')
  }

  return (
    <div className="input-group">
      <div style={buildPreviewStyle({ value })} onClick={openPicker} title={`#${value}`} tabIndex={0} />
      {open && (
        <div style={popoverStyle} onKeyDown={handleEnter}>
          <div style={coverStyle} onClick={closePicker} />
          <TwitterPicker
            colors={colors}
            color={value}
            onChangeComplete={handleChangeComplete}
            styles={TwitterPickerStyles}
          />
        </div>
      )}
    </div>
  )
}

const buildPreviewStyle = ({ value }: { value: string }): React.CSSProperties => {
  let background: string
  if (value === 'transparent') {
    background =
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")'
  } else {
    background = `#${value === 'transparent' ? '00000000' : value} none repeat scroll 0% 0%`
  }

  return {
    background,
    height: 30,
    width: 30,
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 4,
    border: `1px solid ${themeColor('gray-300')}`,
  }
}

const TwitterPickerStyles = {
  default: { input: { height: '30px' } },
}

export const StandaloneColorInput = ({ control }: { control: HTMLInputElement }): JSX.Element => {
  const [value, setValue] = useState(control.value)
  const onColorChange: ColorChangeHandler = useCallback(({ hex }) => setValue(hex), [])
  useEffect(() => {
    control.value = value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <ColorInput onColorChange={onColorChange} value={value} />
}
