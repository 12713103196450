import React, { useState, useEffect, useCallback } from 'react'
import { useTween } from 'react-use'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore FIXME: https://github.com/joshwnj/react-visibility-sensor/issues/148
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor'
import { formatCents, formatNumber, JSONFetcher } from 'shared'
import { CounterWidgetProps } from './types'

/**
 * Markup Example:
 * <span data-behavior="counter-widget"
 *       data-count-to-url="/de/fundraising-events/counters/xmas_streams"
 *       data-count-from="3.142424242"
 *       data-count-duration="5"
 * />
 */

/**
 * @desc Provides animated counting from one number to another.
 */
export const CounterWidget = (props: CounterWidgetProps): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const onChange = useCallback((isVisible: boolean) => {
    setVisible((old) => old || isVisible)
  }, [])
  return (
    <VisibilitySensor scrollCheck onChange={onChange}>
      {/* Keep the placeholder! Without it the box has zero size and never becomes visible. */}
      <span>{visible ? <Counter {...props} /> : ' '}</span>
    </VisibilitySensor>
  )
}

function useCountTo(url: string, defaultCountTo: NullableNumLike): number {
  const [countTo, setCountTo] = useState(+(defaultCountTo ?? 0))
  useEffect(() => {
    if (url) {
      JSONFetcher.load<{ value: number }>({
        url,
        success: ({ value }) => {
          setCountTo(+value)
        },
      })
    }
  }, [url])
  return countTo
}

const Counter = ({
  countTo: countTo_,
  countFrom: countFrom_,
  countDuration: countDuration_,
  countEasing = 'inOutCubic',
  countDelay: countDelay_,
  countToUrl,
  formatPrecision,
  formatCurrency,
}: CounterWidgetProps): JSX.Element => {
  const countFrom = +(countFrom_ || 0)
  const countDuration = 1000 * +(countDuration_ || 3)
  const countDelay = 1000 * +(countDelay_ || 0)
  const countTo = useCountTo(countToUrl, countTo_)
  const tween = useTween(countEasing, countDuration, countDelay)
  return <>{format(countFrom + tween * (countTo - countFrom), formatPrecision, formatCurrency)}</>
}

const format = (number: number, precision_: NullableNumLike, symbol?: string): string => {
  const precision = +(precision_ ?? 0)
  if (symbol) {
    const options = {
      symbol,
      precision,
    }
    return formatCents(number, options)
  }
  return formatNumber(number, { precision })
}
