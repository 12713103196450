import { useEffect, useMemo, useState } from 'react'
import { useOrientation } from 'react-use'
import { themeBreakpoint } from 'shared/helpers/tailwind_theme'

export const useMobile = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const orientation = useOrientation()
  const breakpoint = themeBreakpoint()
  useEffect(() => setWidth(window.innerWidth), [orientation])

  return useMemo(() => width < breakpoint, [width, breakpoint])
}
