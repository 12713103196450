import React, { useEffect } from 'react'
/**
 * @TODO  Re-factor into a hook
 */
export const GoogleTagManager = ({
  gtmId,
  gtmAuth,
  gtmPreview,
}: import('./trackers.d').GoogleTypeManagerProps): null => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })

    const firstScript = document.getElementsByTagName('script')[0]
    const gtmScript = document.createElement('script')
    gtmScript.async = true
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`
    firstScript.parentNode?.insertBefore(gtmScript, firstScript)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // TODO: Determine whether it should depend on ids

  return null
}
