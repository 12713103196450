import React, { useCallback, useState } from 'react'
import { copyToClipboard, gaEvent } from 'shared'
import classNames from 'classnames'
import { CopyComponentProps } from './types'

export const CopyComponent = ({
  value: value_,
  disabled,
  contentType,
  displayValue,
  gaAction,
  gaLabel,
  ctaCopy,
  ctaPreview,
}: CopyComponentProps): JSX.Element => {
  const value = decodeURIComponent(value_)
  const buttonClass = classNames('btn btn-primary', { disabled: disabled })
  const ValueTag = contentType === 'code' ? 'textarea' : 'input'
  const [copyMode, setCopyMode] = useState(false)
  const onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e) => e.target.select(),
    []
  )
  return (
    <div className="generic-copy-component">
      <ValueTag readOnly onFocus={onFocus} value={displayValue || value} />
      <div className="copy-component-sidebar">
        <button
          className={`${buttonClass} ${copyMode ? 'in-copy-mode' : ''}`}
          onClick={() => {
            copyToClipboard(value)
            gaAction && gaEvent('engagement', gaAction, gaLabel)
            setCopyMode(true)
            setTimeout(() => setCopyMode(false), 1000)
          }}
        >
          {copyMode ? I18n.t('misc.generic_copy_alert') : ctaCopy}
        </button>
        {ctaPreview && (
          <a className={buttonClass} href={!disabled ? value : undefined} target="_blank" rel="noreferrer">
            {ctaPreview}
          </a>
        )}
      </div>
    </div>
  )
}
