import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Modal } from '../modal'
import { OverlayWidgetButtonProps, OverlayWidgetProps } from './types'
// TODO: As this won't work (compile time assignment), clarify the intention
const initialBodyOverflow = document.body.style.overflow

export const OverlayWidget = (props: OverlayWidgetProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const onHide = useCallback(() => setShowModal(false), [])
  const onBtnClick = useCallback(() => setShowModal(true), [])
  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : initialBodyOverflow
  }, [showModal])

  return (
    <div>
      <Button onClick={onBtnClick} {...props} />
      {ReactDOM.createPortal(
        showModal ? <Modal onHide={onHide} {...props} /> : null,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.getElementById(props.modalId)!
      )}
    </div>
  )
}

const defaultButtonStyle: React.CSSProperties = {
  backgroundColor: 'rgb(108, 156, 46)',
  backgroundImage: 'none',
  borderBottomColor: 'rgb(94, 136, 40)',
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  borderBottomStyle: 'none',
  borderBottomWidth: 0,
  borderLeftColor: 'rgb(94, 136, 40)',
  borderLeftStyle: 'none',
  borderLeftWidth: 0,
  borderRightColor: 'rgb(94, 136, 40)',
  borderRightStyle: 'none',
  borderRightWidth: 0,
  borderTopColor: 'rgb(94, 136, 40)',
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  borderTopStyle: 'none',
  borderTopWidth: 0,
  boxShadow: 'rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.047) 0px 1px 2px 0px',
  boxSizing: 'border-box',
  color: 'rgb(255, 255, 255)',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: 'Arial',
  fontSize: 18,
  lineHeight: 1.44444,
  fontSizeAdjust: 'none',
  fontStretch: '100%',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  overflow: 'visible',
  paddingBottom: 8,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 8,
  textAlign: 'center',
  textDecoration: 'rgb(255, 255, 255)',
  textDecorationColor: 'rgb(255, 255, 255)',
  textDecorationLine: 'none',
  textDecorationStyle: 'solid',
  textDecorationThickness: 'auto',
  textShadow: 'none',
  textTransform: 'none',
  touchAction: 'manipulation',
  userSelect: 'none',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
}

const Button = ({ onClick, buttonStyle = {}, buttonCaption }: OverlayWidgetButtonProps): JSX.Element => (
  <div className="bp-overlay-button" style={{ ...defaultButtonStyle, ...buttonStyle }} onClick={onClick}>
    {buttonCaption}
  </div>
)
