import React from 'react'
import { unmountComponentAtNode } from 'react-dom'
import { NoticeProps } from './types'

export const Notice = ({ message, type }: NoticeProps): JSX.Element => {
  if (!type) type = 'error'

  const close = (): void => {
    const container = document.getElementById('js-notice-container')
    if (!container) return
    unmountComponentAtNode(container)
  }

  return (
    <div className={`bpc-notice bpc-notice--${type}`}>
      <div className="flex justify-between">
        {typeof message === 'string' ? (
          message
        ) : (
          <ol>
            {message.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ol>
        )}
        <button onClick={close}>
          <span className="fa fa-times"></span>
          <span className="sr-only">{I18n.t('verbs.close')}</span>
        </button>
      </div>
    </div>
  )
}
