import React, { useState, useEffect, useRef, useCallback } from 'react'
import classnames from 'classnames'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import { JSONFetcher, DSIHtml, LogoCompact, Modal, themeColor } from 'shared'
import { useWindowScroll, useClickAway } from 'react-use'
import { GoogleAnalytics } from '../../trackers/google_analytics'
import { GoogleTagManager } from '../../trackers/google_tag_manager'
import { Hotjar } from '../../trackers/hotjar'
import { TrackerProps } from '../../trackers/trackers'

const cookies = new Cookies()

const trackingCookieName = 'betterplace-tracking-accepted'

const sendBrowserEvent = (event_name: string): void => {
  JSONFetcher.post({
    url: '/browser_events',
    body: { event_name: `${event_name}-v3` },
  })
}

export const CookieBanner = (props: TrackerProps): JSX.Element | null => {
  const [trackingAccepted, setTrackingAccepted] = useState(cookies.get(trackingCookieName))
  const [showModal, setShowModal] = useState(false)
  const refCookieBanner = useRef(null)

  const handleCookieChange: CookieChangeListener = useCallback((cookie) => {
    if (cookie.name === trackingCookieName) setTrackingAccepted(cookie.value)
  }, [])

  const acceptTracking = useCallback(() => {
    cookies.set(trackingCookieName, 'yes', { path: '/', expires: new Date(2099, 1, 1) })
    sendBrowserEvent('tracking-accepted')
  }, [])

  const rejectTracking = useCallback(() => {
    cookies.set(trackingCookieName, 'no', { path: '/', expires: new Date(2099, 1, 1) })
    sendBrowserEvent('tracking-rejected')
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
    sendBrowserEvent('tracking-more-information')
  }, [])

  useEffect(() => {
    sendBrowserEvent('tracking-banner-show')
    cookies.addChangeListener(handleCookieChange)
    return () => cookies.removeChangeListener(handleCookieChange)
  }, [handleCookieChange])

  if (trackingAccepted === 'yes')
    return (
      <>
        <GoogleAnalytics {...props} />
        <GoogleTagManager {...props} />
        <Hotjar {...props} />
      </>
    )

  if (trackingAccepted === 'no') return null

  return (
    <div className="generic-cookie-banner bg-teal-700" ref={refCookieBanner}>
      <Autorejecter refCookieBanner={refCookieBanner} rejectTracking={rejectTracking} showModal={showModal} />
      <div className="cookie-content-wrapper">
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          content={<DSIHtml value={I18n.t('layouts.cookie_banner.information_details_html')} />}
          header={I18n.t('layouts.cookie_banner.information_headline')}
          size="modal-lg"
        />

        <div className="row">
          <div className="col-md-4 hidden desktop:block">
            <div className="flex justify-center" style={{ height: '100%' }}>
              <LogoCompact color={themeColor('teal-500')} style={{ width: 135 }} />
            </div>
          </div>
          <div className="col-md-18 px-4">
            <h1 className="text-xl desktop:text-5xl">{I18n.t('layouts.cookie_banner.headline')}</h1>
            <p>{I18n.t('layouts.cookie_banner.introduction_text')}</p>
            <p>{I18n.t('layouts.cookie_banner.call_for_action')}</p>
            <div className="flex my-3 justify-between flex-col desktop:flex-row">
              <div className="flex mb-3">
                <InfoButton className="flex-grow" onClick={openModal} />
              </div>
              <div className="flex flex-col-reverse desktop:flex-row button-wrapper">
                <RejectButton className="flex-grow mb-3" onClick={rejectTracking} />
                <AcceptButton className="flex-grow mb-3" onClick={acceptTracking} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * @desc Component to auto-reject cookies some time after page interaction.
 * must be unmounted to cancel rejection.
 * @TODO TODO: Refactor into a hook
 */
const Autorejecter = ({
  refCookieBanner,
  rejectTracking,
  showModal,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refCookieBanner: React.MutableRefObject<any>
  rejectTracking: () => void
  showModal: boolean
}): null => {
  const [pageUsed, setPageUsed] = useState(false)
  const { y } = useWindowScroll()
  const refFirstScroll = useRef(true)

  // track scrolling on page
  useEffect(() => {
    if (refFirstScroll.current) {
      refFirstScroll.current = false
      return
    }
    setPageUsed(true)
  }, [y, setPageUsed])

  // track clicks outside cookie banner
  useClickAway(refCookieBanner, () => setPageUsed(true))

  // if either happened, start timeout to reject tracking
  useEffect(() => {
    if (!pageUsed || showModal) return
    const timer = setTimeout(() => rejectTracking(), 10000)
    return () => clearTimeout(timer)
  }, [pageUsed, showModal, rejectTracking])

  return null
}

export const CookieBannerLink = ({ className, content }: { className?: string; content?: string }): JSX.Element => {
  /** @type {React.MouseEventHandler} */
  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    cookies.set(trackingCookieName, 'edit', { path: '/', expires: new Date(2099, 1, 1) })
  }

  return (
    <a href="#" className={className ?? ''} onClick={handleClick}>
      {content}
    </a>
  )
}

const AcceptButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('btn btn-primary btn-large', className)} {...props}>
    <i className="fas fa-check" /> <span>{I18n.t('layouts.cookie_banner.accept_button')}</span>
  </button>
)

const RejectButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('btn btn-outline btn-large', className)} {...props}>
    {I18n.t('layouts.cookie_banner.reject_button')}
  </button>
)

const InfoButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('simulated-link', className)} {...props}>
    {I18n.t('layouts.cookie_banner.information_button')}
  </button>
)
